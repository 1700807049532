<template>
  <v-container fluid>
    <v-row>
      <v-col class="pt-0 d-flex align-center flex-column" cols="12">
        <div class="projectName ">{{projectName}}</div>
        <div class="yellowLine"></div>
      </v-col>

      <v-col cols="12">
        <div class="conOfTabs">

          <v-btn
            class="eachBtnEl"
            text
            :to="{
              name: 'PurchasingAllRequests',
              params: { id: $route.params.id },
            }"
            :ripple="false"
            v-if="user.children_id !== 8 && user.children_id !== 5 && user.children_id !== 6"

          >
            Purchasing
            <span class="conOfTotal">{{ listData.requests_purchasing }}</span>
          </v-btn>
          <v-btn
            class="eachBtnEl"
            text
            :to="{
              name: 'printingAllRequests',
              params: { id: $route.params.id },
            }"
            :ripple="false"
            v-if="user.children_id !== 7 && user.children_id !== 8 && user.children_id !== 6"
          >
            Printing
            <span class="conOfTotal">{{ listData.requests_printing }}</span>
          </v-btn>
          <v-btn
            class="eachBtnEl"
            text
            :to="{
              name: 'productionAllRequests',
              params: { id: $route.params.id },
            }"
            :ripple="false"
            v-if="user.children_id !== 7 && user.children_id !== 5 && user.children_id !== 6"

          >
            Production
            <span class="conOfTotal">{{ listData.requests_production }}</span>
          </v-btn>
          <v-btn
            class="eachBtnEl"
            text
            :to="{
              name: 'photographyAllRequests',
              params: { id: $route.params.id },
            }"
            :ripple="false"
            v-if="user.children_id !== 7 && user.children_id !== 5 && user.children_id !== 8"

          >
            Photography
            <span class="conOfTotal">{{ listData.requests_photography }}</span>
          </v-btn>

          <v-btn
            class="eachBtnEl"
            text
            :to="{
              name: 'extrasAllRequests',
              params: { id: $route.params.id },
            }"
            :ripple="false"
          >
            Extras
            <span class="conOfTotal">{{ listData.requests_extras }}</span>
          </v-btn>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import { ApiService } from "@/modules/shared/services/api";
import store from "@/store";

let apiServices = new ApiService();
export default {
  data: () => ({
    listData: [],
  }),
  methods: {
    getProjectdata(){
      apiServices.get(`projects/${this.$route.params.id}`).then((res) => {
        if (res) {
          this.listData = res.data;
          store.commit("setCreatedByUser", res.data.user);
          store.commit("setAssignTo", res.data.assign_to);
          store.commit("setProjectCountry", res.data.country_id);
          store.commit("setProjectName", res.data.project_name);
          store.commit("setpo_number", res.data.po_number);
        }
      });
    }
  },
  mounted() {
   this.getProjectdata(this.$route.params.id)
  },

  computed: {
    ...mapGetters(["user","projectName"]),
  },
  watch: {

    "$route.params.query": {
      handler: function () {
        this.getProjectdata(this.$route.params.id);
      },
      deep: true,
      immediate: true,
    },
  },
};
</script>
<style lang="scss" scoped>
@import "./_requestTabs.scss";
</style>
